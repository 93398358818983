export const appLanguages = {
    en: {
        label: "English",
        code: "en",
        // icon: require("../../assets/images/language/en.png"),
        isRtl: false,
    },
    ar: {
        label: "عربي",
        code: "ar",
        // icon: require("../../assets/images/language/ar.png"),
        isRtl: true,
    },
};
