import AppButton from "../../../../components/general/app-button/AppButton";
import Card from "../../../../components/general/card/Card";
import Title from "../../../../components/general/title/Title";
import AppInput from "../../../../components/general/app-input/AppInput";
import Label from "../../../../components/general/label/Label";
import React from "react";
import Styles from "./AddDepartment.module.css";
import useLogic from "../../../../helpers/hooks/use-logic";
import Logic, { INITIAL_STATE } from "./logic";
import LoadingSpinner from "../../../../components/general/loading-spinner/LoadingSpinner";
import useValidator from "../../../../helpers/hooks/use-validator";
import ValidationProvider from "../../../../components/general/validation-provider/ValidationProvider";
import { useTranslation } from "react-i18next";

const AddDepartment = () => {
    const { t } = useTranslation();
    const { state, updateState, handleUpdateOrCreate, navigate, params } = useLogic({
        INITIAL_STATE,
        Logic,
    });
    const { errors, onBlur, onChange, handleSubmit } = useValidator({
        defaultValues: {
            ...state.form,
        },
        setValue: (nextFormState) => {
            updateState({
                prop: "form",
                value: nextFormState,
            });
        },
        onSubmit: handleUpdateOrCreate,
        validations: {
            name: {
                required: true,
                validation_name: "Department",
            },
            // shortName: {
            //     required: true,
            //     validation_name: "Short name",
            // },
        },
    });
    return (
        <div className={Styles.container}>
            <div className={Styles.title}>
                <Title
                    title={`${params.get("id") ? t("update") : t("add")} ${t("department")}`}
                ></Title>
            </div>
            <Card style={{ padding: "20px 10px" }}>
                <LoadingSpinner isLoading={state.pageLoading} error={state.error}>
                    <div className={Styles.formContainer}>
                        <div style={{ width: "50%", marginBottom: "60px" }}>
                            <div className={Styles.profilePictureContainer}>
                                {/* <div style={{ flex: 1 }}>
                                    <img
                                        src={require("../../../../assets/images/defaultProfilePicture.png")}
                                        width={"100%"}
                                        height={"auto"}
                                        style={{ borderRadius: "70px" }}
                                    />
                                </div> */}
                                <div style={{ flex: 1 }}>
                                    <div className={Styles.groupControl}>
                                        <ValidationProvider
                                            error={errors.name}
                                            render={({ requiredTitle }) => (
                                                <Label
                                                    name={requiredTitle(t("department"))}
                                                    className={Styles.label}
                                                >
                                                    <AppInput
                                                        type="text"
                                                        className={Styles.input}
                                                        value={state.form.name}
                                                        onChange={(e) =>
                                                            onChange("name", e.target.value)
                                                        }
                                                        onBlur={(e) => onBlur("name")}
                                                    />
                                                </Label>
                                            )}
                                        />
                                    </div>
                                    {/* <div className={Styles.groupControl}>
                                        <ValidationProvider
                                            error={errors.shortName}
                                            render={({ requiredTitle }) => (
                                                <Label
                                                    name={t("shortName")}
                                                    className={Styles.label}
                                                >
                                                    <AppInput
                                                        type="text"
                                                        className={Styles.input}
                                                        value={state.form.shortName}
                                                        onChange={(e) =>
                                                            onChange("shortName", e.target.value)
                                                        }
                                                        onBlur={(e) => onBlur("shortName")}
                                                    />
                                                </Label>
                                            )}
                                        />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={Styles.actionButton}>
                        <AppButton
                            className="primaryButton"
                            type="button"
                            value={t("save")}
                            onClick={handleSubmit}
                            isLoading={state.saveLoading}
                        />
                        <AppButton
                            className="whiteButton"
                            onClick={() => navigate("/portal/departments")}
                            value={t("cancel")}
                            style={{ margin: "0px 15px" }}
                        />
                    </div>
                </LoadingSpinner>
            </Card>
        </div>
    );
};
export default AddDepartment;
