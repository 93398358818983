import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import {
    getSpecificAuditTemplate,
    updateOrCreateAuditTemplates,
} from "../../../../api/services/AuditServices";
import { getAllDepartments } from "../../../../api/services/DepartmentServices";
import { fetchAllUsers } from "../../../../api/services/UserServices";
import { ERROR } from "../../../../components/general/app-toast/AppToast";
import UseAppToast from "../../../../helpers/hooks/use-app-toast";
import { INITIAL_STATE } from "../../../../helpers/RtCommon";
import { updateState } from "../../../../store/Actions";
import { UPDATE_PROP } from "../../../../store/ActionTypes";
import { INITIAL_STATE as initalSurvey } from "../../../../store/Constants";
import { extractErrorMessage } from "../../../../helpers/Common";

const Logic = () => {
    let navigate = useNavigate();

    const reduxState = useSelector((state) => state.survey);
    const assignees = useSelector((state) => {
        return { ...INITIAL_STATE, results: state.survey.surveyDetails.assignees };
    });
    const { id } = useParams();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const duplicate = queryParams.get("duplicate") ?? false; // this will get the value of duplicate, e.g., "true"

    const { addToast } = UseAppToast();
    const reduxDispatch = useDispatch();

    useEffect(() => {
        reduxDispatch({
            type: UPDATE_PROP,
            prop: "survey.loading",
            value: true,
        });
        getAllDepartments(
            (res) => {
                // console.log(res);
                reduxDispatch({
                    type: UPDATE_PROP,
                    prop: "survey.masterData.departments",
                    value: res.data.response.data,
                });
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {
                reduxDispatch({
                    type: UPDATE_PROP,
                    prop: "survey.loading",
                    value: false,
                });
            },
            {}
        );
        fetchAllUsers(
            (res) => {
                reduxDispatch({
                    type: UPDATE_PROP,
                    prop: "survey.masterData.users",
                    value: res.data.response,
                });
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            () => {},
            { pageSize: 99999 }
        );
        // getTags(
        //     (res) => {
        //         reduxDispatch({
        //             type: UPDATE_PROP,
        //             prop: "survey.masterData.dataTags",
        //             value: res.data.response.data,
        //         });
        //     },
        //     (error) => {
        //         addToast({
        //             type: ERROR,
        //             description: extractErrorMessage(error),
        //             title: "Error",
        //         });
        //     },
        //     () => {
        //         reduxDispatch({
        //             type: UPDATE_PROP,
        //             prop: "survey.loading",
        //             value: false,
        //         });
        //     },
        //     {}
        // );
        if (id) {
            reduxDispatch({
                type: UPDATE_PROP,
                prop: "survey.loading",
                value: true,
            });

            getSpecificAuditTemplate(
                (res) => {
                    reduxDispatch({
                        type: UPDATE_PROP,
                        prop: "survey",
                        value: (v) => {
                            return {
                                ...v,
                                id: res.data.response.id,
                                surveyDetails: {
                                    show_sign: res.data.response.show_sign,
                                    sign_required: res.data.response.sign_required,
                                    show_incharge_sign: res.data.response.show_incharge_sign,
                                    incharge_sign_required:
                                        res.data.response.incharge_sign_required,
                                    image: res.data.response.image,
                                    assignees: res.data.response.json_structure.assignees,
                                    name: duplicate
                                        ? res.data.response.name + " - Copy"
                                        : res.data.response.name,
                                    short_name: res.data.response.short_name,
                                    sign_label: res.data.response.sign_label,
                                    incharge_sign_label: res.data.response.incharge_sign_label,
                                    department_id: {
                                        label: res.data.response.department_name,
                                        value: res.data.response.department_id,
                                    },
                                    translations: res.data.response.translations,
                                },
                                sections: res.data.response.json_structure.sections,
                            };
                        },
                    });
                },
                (error) => {
                    addToast({
                        type: ERROR,
                        description: extractErrorMessage(error),
                        title: "Error",
                    });
                },
                () => {
                    reduxDispatch({
                        type: UPDATE_PROP,
                        prop: "survey.loading",
                        value: false,
                    });
                },
                { id }
            );
        }
    }, []);
    const saveAuditTemplate = (props = {}) => {
        const { closeOnSave } = props;
        reduxDispatch({
            type: UPDATE_PROP,
            prop: closeOnSave ? "survey.saveNCloseLoading" : "survey.saveLoading",
            value: true,
        });
        const formData = new FormData();
        formData.append("name", reduxState.surveyDetails.name);
        formData.append("short_name", reduxState.surveyDetails.short_name);
        formData.append("translations", JSON.stringify(reduxState.surveyDetails.translations));
        formData.append(
            "json_structure",
            JSON.stringify({
                assignees: reduxState.surveyDetails.assignees,
                sections: reduxState.sections,
            })
        );
        // formData.append("sign_label", reduxState.surveyDetails.sign_label);
        formData.append("sign_required", reduxState.surveyDetails.sign_required ? 1 : 0);
        formData.append("show_sign", reduxState.surveyDetails.show_sign ? 1 : 0);
        formData.append("show_incharge_sign", reduxState.surveyDetails.show_incharge_sign ? 1 : 0);
        formData.append(
            "incharge_sign_required",
            reduxState.surveyDetails.incharge_sign_required ? 1 : 0
        );
        if (reduxState.surveyDetails.sign_label) {
            formData.append("sign_label", reduxState.surveyDetails.sign_label);
        }
        if (reduxState.surveyDetails.incharge_sign_label) {
            formData.append("incharge_sign_label", reduxState.surveyDetails.incharge_sign_label);
        }
        if (reduxState.surveyDetails.image) {
            formData.append("image", reduxState.surveyDetails.image);
        }
        // formData.append("questions_images[]", reduxState.images);
        for (const key in reduxState.images) {
            formData.append("questions_images[]", reduxState.images[key]);
        }
        // debugger;
        if (reduxState.surveyDetails?.department_id?.value) {
            formData.append("department_id", reduxState.surveyDetails.department_id?.value);
        }

        if (id && !duplicate) {
            formData.append("id", id);
        }

        updateOrCreateAuditTemplates(
            (res) => {
                console.log("=================audit===================");
                console.log(res);
                console.log(res.data.response.id);
                // check if id is returned and it didn't exist before
                if (!id && res.data.response.id) {
                    //   add new id to the url
                    navigate(`/portal/form-builder/${res.data.response.id}`);
                }
                addToast({
                    type: "success",
                    description: `${
                        duplicate ? "Added" : id ? "Updated" : "Added"
                    } Template Successfully`,
                    title: "Success",
                });

                closeOnSave &&
                    reduxDispatch({
                        type: UPDATE_PROP,
                        prop: "survey",
                        value: (v) => {
                            return {
                                ...v,
                                surveyDetails: {
                                    ...initalSurvey.survey.surveyDetails,
                                },
                                sections: [],
                                selectedQuestion: null,
                            };
                        },
                    });

                closeOnSave && navigate("/portal/forms-checklists");

                console.log(res);
            },
            (error) => {
                addToast({
                    type: ERROR,
                    description: extractErrorMessage(error),
                    title: "Error",
                });
            },
            (f) => {
                reduxDispatch({
                    type: UPDATE_PROP,
                    prop: closeOnSave ? "survey.saveNCloseLoading" : "survey.saveLoading",
                    value: false,
                });
            },
            { formData }
        );
    };
    const updateAuditDetails = (auditPayload) => {
        let payload = [];
        let propPrefix = `survey`;
        auditPayload.forEach((element) => {
            payload.push({
                type: element.type,
                prop: propPrefix + `.${element.prop}`,
                value: element.value,
            });
        });
        reduxDispatch(updateState(payload));
    };
    return {
        reduxState,
        updateAuditDetails,
        assignees,
        reduxDispatch,
        saveAuditTemplate,
        id,
        navigate,
        duplicate,
    };
};
export default Logic;
