import React, { useState } from "react";
import Styles from "./FormBuilder.module.css";
import Card from "../../../../components/general/card/Card";
import Basic from "./components/basic/Basic";
import Structure from "./components/structure/Structure";
import TabsLayout from "../../../../components/general/tabs-layout/TabsLayout";
import AppButton from "../../../../components/general/app-button/AppButton";
import Logic from "./logic";
import { UPDATE_PROP } from "../../../../store/ActionTypes";
import LoadingSpinner from "../../../../components/general/loading-spinner/LoadingSpinner";
import useValidator from "../../../../helpers/hooks/use-validator";
import { useTranslation } from "react-i18next";
const FormBuilder = () => {
    const { t } = useTranslation();
    const { reduxState, id, updateAuditDetails, saveAuditTemplate, navigate } = Logic();
    // console.log("typeof reduxState.surveyDetails.image === string");
    // console.log(typeof reduxState.surveyDetails.image === "string");
    const [closeOnSubmit, setCloseOnSubmit] = useState(false);
    const { errors, onBlur, onChange, handleSubmit } = useValidator({
        defaultValues: {
            ...reduxState.surveyDetails,
            sections: reduxState.sections,
        },
        setValue: (nextFormState) => {
            // updateState({
            //   prop: "form",
            //   value: nextFormState,
            // });
            updateAuditDetails([
                {
                    type: UPDATE_PROP,
                    prop: `surveyDetails.name`,
                    value: nextFormState.name,
                },
                {
                    type: UPDATE_PROP,
                    prop: `surveyDetails.short_name`,
                    value: nextFormState.short_name,
                },
                {
                    type: UPDATE_PROP,
                    prop: `surveyDetails.department_id`,
                    value: nextFormState.department_id,
                },
                {
                    type: UPDATE_PROP,
                    prop: `surveyDetails.image`,
                    value: nextFormState.image,
                },
                {
                    type: UPDATE_PROP,
                    prop: `surveyDetails.assignees`,
                    value: nextFormState.assignees,
                },
                {
                    type: UPDATE_PROP,
                    prop: `surveyDetails.sign_label`,
                    value: nextFormState.sign_label,
                },
                {
                    type: UPDATE_PROP,
                    prop: `surveyDetails.incharge_sign_label`,
                    value: nextFormState.incharge_sign_label,
                },
                {
                    type: UPDATE_PROP,
                    prop: `surveyDetails.translations`,
                    value: nextFormState.translations,
                },
            ]);
        },
        onSubmit: saveAuditTemplate,
        validations: {
            name: {
                required: true,
                validation_name: "Audit name",
            },
            // short_name: { required: true, validation_name: "Short name" },
            // assignees: { required: true, validation_name: "Assignees" },
            image: {
                validation_name: "Image",
                isImage: true,
                // required: typeof reduxState.surveyDetails.image === "string",
            },
            sections: { required: true, validation_name: "Sections" },
            // department_id: { required: true, validation_name: "Department" },
            sign_label: {
                validate: () => {
                    return (
                        reduxState.surveyDetails.sign_required &&
                        !!!reduxState.surveyDetails.sign_label.trim()
                    );
                },
                validation_name: "Sign label",
            },
            incharge_sign_label: {
                validate: () => {
                    return (
                        reduxState.surveyDetails.incharge_sign_required &&
                        !!!reduxState.surveyDetails.incharge_sign_label.trim()
                    );
                },
                validation_name: "incharge sign label",
            },
        },
    });

    console.log("reduxState");
    console.log(reduxState);
    const tabsList = [
        {
            label: t("basic"),
            key: "basic",
            renderContent: (
                <Card style={{ padding: "20px 10px" }}>
                    <LoadingSpinner isLoading={reduxState.loading}>
                        <Basic id={id} errors={errors} onChange={onChange} onBlur={onBlur} />
                    </LoadingSpinner>
                </Card>
            ),
            required: true,
            error:
                errors.name.error ||
                errors.short_name.error ||
                errors.image.error ||
                errors.sign_label.error ||
                errors.incharge_sign_label.error,
        },
        {
            label: t("structure"),
            key: "structure",
            renderContent: <Structure id={id} errors={errors} onBlur={onBlur} />,
            required: true,
            error: errors.sections.error,
        },
    ];
    return (
        <div className={Styles.container}>
            <TabsLayout
                tabsList={tabsList}
                title={t("formBuilder")}
                unmountOnExit={false}
                renderButton={
                    <div style={{ display: "flex" }}>
                        <AppButton
                            className="primaryButton"
                            onClick={(e) => {
                                handleSubmit(e, { closeOnSave: true });
                            }}
                            value={t("saveAndClose")}
                            isLoading={reduxState.saveNCloseLoading}
                            style={{ margin: "0px 10px" }}
                        />
                        <AppButton
                            className="primaryButton"
                            onClick={(e) => {
                                handleSubmit(e, { closeOnSave: false });
                            }}
                            value={t("save")}
                            isLoading={reduxState.saveLoading}
                            style={{ margin: "0px 10px" }}
                        />
                        <AppButton
                            className="whiteButton"
                            onClick={() => {
                                navigate("/portal/forms-checklists");
                            }}
                            value={t("cancel")}
                        />
                    </div>
                }
            />
        </div>
    );
};
export default FormBuilder;
