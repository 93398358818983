import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import Chart from "react-apexcharts";
import MultiSelect from "react-select";
import AppInput from "../../../components/general/app-input/AppInput";
import Card from "../../../components/general/card/Card";
import Label from "../../../components/general/label/Label";
import Spacer from "../../../components/general/spacer/Spacer";
import Title from "../../../components/general/title/Title";
import useLogic from "../../../helpers/hooks/use-logic";
import Layout from "../components/layout/Layout";
import styles from "./PerformanceNew.module.css";
import { filterSelectStyles, INITIAL_STATE } from "./constants";
import { useTranslation } from "react-i18next";

import Logic from "./logic";

const PerformanceN = () => {
    const { state, updateState, actions } = useLogic({
        INITIAL_STATE,
        Logic,
    });
    const { t } = useTranslation();
    const { stackedBarChart, lineChart, heatMap } = state;
    const { dataBarChart, dataLineChart, dataHeatChart } = actions;

    useEffect(() => {
        dataBarChart();
        dataLineChart();
        dataHeatChart();
    }, [state.filters]);

    return (
        <>
            <Layout>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                        width: "100%",
                    }}
                >
                    <Layout.Filter
                        onClick={() => {
                            actions?.filterData();
                        }}
                        filters={[
                            {
                                key: "From",
                                value: state.dateFrom,
                            },
                            {
                                key: "To",
                                value: state.dateTo,
                            },
                            {
                                key: "Audit Name",
                                value: state.selectedAudit,
                            },
                            {
                                key: "Location",
                                value: state.selectedLocation,
                            },
                            {
                                key: "Auditor",
                                value: state.selectedAuditor,
                            },
                            {
                                key: "Department",
                                value: state.selectedDepartment,
                            },
                            {
                                key: "Area",
                                value: state.selectedArea,
                            },
                        ]}
                    >
                        <div className={styles.groupControl}>
                            <Label
                                name={t("from")}
                                className={styles.label}
                                classText={styles.labelText}
                            >
                                <div className={styles.inputContainer}>
                                    <AppInput
                                        onChange={(e) =>
                                            updateState({ prop: "dateFrom", value: e.target.value })
                                        }
                                        value={state.dateFrom}
                                        onBlur={(e) => {
                                            console.log(e);
                                        }}
                                        type="date"
                                        containerStyle={{
                                            height: "100%",
                                            padding: "0px 10px",
                                            position: "relative",
                                        }}
                                        className={styles.inputDate}
                                    >
                                        <span className={styles.openbutton}>
                                            <FontAwesomeIcon
                                                icon={faCalendarAlt}
                                                style={{ fontSize: 20, color: "var(--primary)" }}
                                            />
                                        </span>
                                    </AppInput>
                                </div>
                            </Label>
                        </div>
                        <div className={styles.groupControl}>
                            <Label
                                name={t("to")}
                                className={styles.label}
                                classText={styles.labelText}
                            >
                                <div className={styles.inputContainer}>
                                    <AppInput
                                        onChange={(e) =>
                                            updateState({ prop: "dateTo", value: e.target.value })
                                        }
                                        value={state.dateTo}
                                        type="date"
                                        containerStyle={{
                                            height: "100%",
                                            padding: "0px 10px",
                                            position: "relative",
                                        }}
                                        className={styles.inputDate}
                                    >
                                        <span className={styles.openbutton}>
                                            <FontAwesomeIcon
                                                icon={faCalendarAlt}
                                                style={{ fontSize: 20, color: "var(--primary)" }}
                                            />
                                        </span>
                                    </AppInput>
                                </div>
                            </Label>
                        </div>
                        <div className={styles.groupControl}>
                            <Label
                                name={t("auditName")}
                                className={styles.label}
                                classText={styles.labelText}
                            >
                                <div className={styles.inputContainer}>
                                    <MultiSelect
                                        options={state.audits.data.map((element, index) => ({
                                            value: element.id,
                                            label: element.name ?? "Audit " + index,
                                        }))}
                                        styles={filterSelectStyles}
                                        value={state.selectedAudit}
                                        onChange={(e) => {
                                            console.log(e);
                                            updateState({ prop: "selectedAudit", value: e });
                                        }}
                                        isMulti
                                    />
                                </div>
                            </Label>
                        </div>
                        <div className={styles.groupControl}>
                            <Label
                                name={t("location")}
                                className={styles.label}
                                classText={styles.labelText}
                            >
                                <div className={styles.inputContainer}>
                                    <MultiSelect
                                        options={state.locations.data.map((element) => ({
                                            value: element.id,
                                            label: element.name,
                                        }))}
                                        styles={filterSelectStyles}
                                        value={state.selectedLocation}
                                        onChange={(e) => {
                                            console.log(e);
                                            updateState({ prop: "selectedLocation", value: e });
                                        }}
                                        isMulti
                                    />
                                </div>
                            </Label>
                        </div>
                        <div className={styles.groupControl}>
                            <Label
                                name={t("auditor")}
                                className={styles.label}
                                classText={styles.labelText}
                            >
                                <div className={styles.inputContainer}>
                                    <MultiSelect
                                        options={state.auditors.data.map((element) => ({
                                            value: element.id,
                                            label: element.name,
                                        }))}
                                        styles={filterSelectStyles}
                                        value={state.selectedAuditor}
                                        onChange={(e) => {
                                            console.log(e);
                                            updateState({ prop: "selectedAuditor", value: e });
                                        }}
                                        isMulti
                                    />
                                </div>
                            </Label>
                        </div>
                        <div className={styles.groupControl}>
                            <Label
                                name={t("department")}
                                className={styles.label}
                                classText={styles.labelText}
                            >
                                <div className={styles.inputContainer}>
                                    <MultiSelect
                                        options={state.departments.data.map((element) => ({
                                            value: element.id,
                                            label: element.name,
                                        }))}
                                        styles={filterSelectStyles}
                                        value={state.selectedDepartment}
                                        onChange={(e) => {
                                            console.log(e);
                                            updateState({ prop: "selectedDepartment", value: e });
                                        }}
                                        isMulti
                                    />
                                </div>
                            </Label>
                        </div>
                        <div className={styles.groupControl}>
                            <Label
                                name={t("area")}
                                className={styles.label}
                                classText={styles.labelText}
                            >
                                <div className={styles.inputContainer}>
                                    <MultiSelect
                                        options={state.area.data.map((element) => ({
                                            value: element.id,
                                            label: element.name,
                                        }))}
                                        styles={filterSelectStyles}
                                        value={state.selectedArea}
                                        onChange={(e) => {
                                            console.log(e);
                                            updateState({ prop: "selectedArea", value: e });
                                        }}
                                        isMulti
                                    />
                                </div>
                            </Label>
                        </div>
                    </Layout.Filter>
                    <Layout.Body>
                        <div className={styles.content}>
                            <Card>
                                <Title title={t("OverallLocationPerformanceByAudit")} />
                                {state.stackedBarChartLoading ? (
                                    <div
                                        style={{
                                            display: "flex",
                                            flex: 1,
                                            width: "100%",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            icon={faSpinner}
                                            style={{
                                                color: "var(--primary)",
                                                fontSize: "45px",
                                            }}
                                            spin
                                        />
                                    </div>
                                ) : null}
                                <Chart
                                    options={stackedBarChart.options}
                                    series={stackedBarChart.series}
                                    type="bar"
                                    height={400}
                                />
                            </Card>
                            <Spacer height={10} />
                            <Card>
                                <Title title={"Line Chart"} />
                                {state.lineChartLoading ? (
                                    <div
                                        style={{
                                            display: "flex",
                                            flex: 1,
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            icon={faSpinner}
                                            style={{
                                                color: "var(--primary)",
                                                fontSize: "45px",
                                            }}
                                            spin
                                        />
                                    </div>
                                ) : null}
                                <Chart
                                    options={lineChart.options}
                                    series={lineChart.series}
                                    type="line"
                                    height={400}
                                />
                            </Card>
                            <Spacer height={10} />
                            <Card>
                                <Title title={"HeatMap"} />
                                {state.heatMapLoading ? (
                                    <div
                                        style={{
                                            display: "flex",
                                            flex: 1,
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            icon={faSpinner}
                                            style={{
                                                color: "var(--primary)",
                                                fontSize: "45px",
                                            }}
                                            spin
                                        />
                                    </div>
                                ) : null}
                                <Chart
                                    options={heatMap.options}
                                    series={heatMap.series}
                                    type="heatmap"
                                    height={400}
                                />
                            </Card>
                            <Spacer height={10} />
                        </div>
                    </Layout.Body>
                </div>
            </Layout>
        </>
    );
};
export default PerformanceN;
